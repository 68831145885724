<template>
  <div class="container">
    <back></back>
    <div class="header">
      <img
        width="600px"
        height="auto"
        src="https://oss.culturalcloud.net/furong/202402/01223912487i.png"
        alt=""
      />
    </div>
    <div class="body">
      <div class="culture-box">
        <div style="font-size: 30px; font-weight: bold; color: #b0835a">
          我们是万图信息一五悦人<br />
          我为能够成为五悦人而骄傲和自豪。
        </div>
        <div class="culture">
          <div class="title">经营理念:</div>
          <div>专注文教产业，信息改变未来！</div>
        </div>
        <div class="culture">
          <div class="title">使命:</div>
          <div>悦享美好生活，助力科教强国！</div>
        </div>
        <div class="culture">
          <div class="title">愿景:</div>
          <div>
            扬中华文化，立民族脊梁<br />
            不断满足人们对美好生活的向往！
          </div>
        </div>
        <div class="culture">
          <div class="title">核心价值观:</div>
          <div>共识、共知、共行、共享、利他！</div>
        </div>
        <div class="culture">
          <div class="title">品牌口号:</div>
          <div>五悦云引领-助推未来！</div>
        </div>
        <div class="culture">
          <div class="title">招商语:</div>
          <div>五悦云，筋斗云，耀五岳，文化魂</div>
        </div>
      </div>
      <div class="right">
        <div class="product">
          <img
            @click="product(1)"
            src="https://oss.culturalcloud.net/furong/202210/31173239x0m5.png"
            alt=""
          />
          <img
            @click="product(2)"
            src="https://oss.culturalcloud.net/furong/202210/31173315eiue.png"
            alt=""
          />
          <img
            @click="product(3)"
            src="https://oss.culturalcloud.net/furong/202211/131556274ovi.png"
            alt=""
          />
        </div>
        <div class="amb">
          <img
            @click="amb(item)"
            v-for="(item, index) in ambList"
            :key="index"
            :src="item.titleImg"
            alt=""
          />
        </div>
      </div>
    </div>
    <el-dialog class="product-window" :visible.sync="productWindow" width="80%">
      <iframe
        v-if="productType == 1"
        style="height: 80vh; width: 100%"
        src="https://read.culturalcloud.net/wyy-meeting/202211/210840000zpa.pdf"
        frameborder="0"
      ></iframe>
      <swiper v-if="productType == 2" :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(item, index) in productImg" :key="index">
          <img
            :src="item"
            style="width: 100%; height: 100%; object-fit: cover"
            alt=""
          />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev" style=""></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <swiper v-if="productType == 3" :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(item, index) in beliefs" :key="index">
          <img
            :src="item"
            style="width: 100%; height: 100%; object-fit: cover"
            alt=""
          />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev" style=""></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </el-dialog>
    <el-dialog
      :destroy-on-close="true"
      class="amb-window"
      :visible.sync="ambWindow"
      width="80%"
    >
      <div style="width: 100%; height: 100%; overflow: auto">
        <img style="width: 100%" :src="ambObj.img" alt="" />
      </div>
      <template #title>
        <div class="amb-banner">
          <img :src="ambObj.banner" alt="" />
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import back from "@/components/back";
export default {
  components: {
    swiper,
    swiperSlide,
    back,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  data() {
    return {
      productWindow: false,
      productType: 1, //1.pdf 2.图片列表
      productImg: [
        "https://oss.culturalcloud.net/furong/202210/212001042m8r.png",
        "https://oss.culturalcloud.net/furong/202210/212001157dpe.png",
        "https://oss.culturalcloud.net/furong/202210/212001122hrp.png",
        "https://oss.culturalcloud.net/furong/202210/212001059d1b.png",
        "https://oss.culturalcloud.net/furong/202210/212001042l6b.png",
        "https://oss.culturalcloud.net/furong/202210/21200106dbl7.png",
        "https://oss.culturalcloud.net/furong/202210/21200113omkp.png",
        "https://oss.culturalcloud.net/furong/202210/2120010413p7.png",
        "https://oss.culturalcloud.net/furong/202210/302040327opb.png",
      ],
      beliefs: [
        "https://oss.culturalcloud.net/furong/202211/131602280585.png",
        "https://oss.culturalcloud.net/furong/202211/13160646vff6.jpg",
        "https://oss.culturalcloud.net/furong/202407/011232157gcu.png",
        "https://oss.culturalcloud.net/furong/202407/01123441v165.jpg",
      ],
      ambWindow: false,
      ambList: [
        {
          title: "六项精进",
          titleImg:
            "https://oss.culturalcloud.net/furong/202210/30195439fzb1.png",
          banner:
            "https://oss.culturalcloud.net/furong/202210/201623550yo0.png",
          img: "https://oss.culturalcloud.net/furong/202210/30211309qf7q.png",
        },
        {
          title: "经营十二条",
          titleImg:
            "https://oss.culturalcloud.net/furong/202210/30195454tq70.png",
          banner:
            "https://oss.culturalcloud.net/furong/202210/20162914qlna.png",
          img: "https://oss.culturalcloud.net/furong/202210/20162947le3a.png",
        },
        {
          title: "PDCA循环改善",
          titleImg:
            "https://oss.culturalcloud.net/furong/202210/30195507jrsy.png",
          banner:
            "https://oss.culturalcloud.net/furong/202210/20151817yc56.png",
          img: "https://oss.culturalcloud.net/furong/202210/20152745rvqf.png",
        },
      ],
      ambObj: {},
      swiperOption: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  methods: {
    amb(item) {
      this.ambWindow = true;
      this.ambObj = item;
    },
    product(type) {
      this.productType = type;
      this.productWindow = true;
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background-image: url("https://oss.culturalcloud.net/tianxin/202211/02145649rzn5.png");
  background-position: 0% 0%;
}

.header {
  width: 100%;
  padding: 50px 80px 0;
  text-align: right;
}

.body {
  width: 100%;
  padding: 40px 150px 0;
  display: flex;
  justify-content: space-between;
}

.culture-box {
  .culture {
    width: 450px;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.9);
    margin-top: 30px;

    .title {
      font-size: 25px;
      font-weight: bold;
      color: #a96f3c;
    }
  }

  .culture:before {
    content: "";
    display: block;
    width: 40px;
    height: 5px;
    background-color: #a96f3c;
    margin-bottom: 5px;
  }
}

.right {
  width: 690px;
  height: 685px;
  display: flex;

  .product {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;

    img {
      width: 100%;
      height: 33.33%;
      cursor: pointer;
      object-fit: cover;
    }
  }

  .amb {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-left: 5px;

    img {
      height: 33.33%;
      cursor: pointer;
    }
  }
}

::v-deep .product-window .el-dialog,
::v-deep .amb-window .el-dialog {
  margin-top: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f9f9f8 !important;
}

::v-deep .amb-window .el-dialog {
  height: 900px !important;
}

::v-deep .amb-window .el-dialog__body {
  margin-left: 310px;
  padding: 30px 60px;
  height: calc(100% - 30px);
  overflow: hidden;
}

.amb-window .amb-banner {
  width: 310px;
  height: 900px;
  position: absolute;
  left: 0;
  top: 0;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
